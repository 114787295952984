import StyledTypography from './styles'

function Text(props) {
	return (
		<StyledTypography
			className={props.className}
			variant={props.variant}
			component={props.component}
			style={props.style}
			paragraph={props.paragraph}
		>
			{props.children}
		</StyledTypography>
	)
}

export default Text