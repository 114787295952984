import { Toolbar } from '@material-ui/core'
import styled from 'styled-components'

export const StyledToolBar = styled(Toolbar)`
  background-color: var(--background-color);
  color: white;
  justify-content: space-between;

  .desktop-toolbar {
    display: flex;
    align-items: center;
  }

  @media (min-width: 1100px) {
    .mobile-toolbar {
      margin: 0;
      display: none;
    }
    
    div button {
      margin-right: 40px;
    }

    background-color: var(--secondary-color);
  }

  @media (max-width: 1100px) {
    .desktop-toolbar {
      display: none;
    }
  }
`

export const UserProfileContainer = styled.div`
  margin-top: auto;
  background-color: #0000004D;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`