import { Typography } from '@material-ui/core'
import styled from 'styled-components'

const StyledTypography = styled(Typography)`
	color: var(--font-color);
	color: ${props =>
		(props.variant === "body1" && "var(--secondary-font-color)") ||
		(props.variant === "body2" && "var(--secondary-font-color)") ||
		(props.variant === "caption" && "var(--secondary-font-color)")
	};
	
    letter-spacing: 0.16px;
    font-style: normal;
    line-height: 24px;
    text-align: left;
`

export default StyledTypography