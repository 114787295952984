import { Drawer } from '@material-ui/core'
import styled from 'styled-components'

export const StyledDrawer = styled(Drawer)`
	.MuiDrawer-paper {
		color: var(--font-color);
		background-color: var(--secondary-color);
		width: ${props =>
			(props.anchor === "bottom" && "100%") || 
			(props.anchor === "left" && "80%") ||
			(props.anchor === "right" && (props.id === "add-offer-menu" ? "35%" : "60%"))
		};
		border-radius: ${props => props.anchor === "bottom" ? "4px" : "0"}; 

		div button {
			padding: 12px 16px;
		}

		a {
			width: 140px;
		}

		hr {
			width: 100%;
			background-color: #535353;
		}

		.flex-container {
			align-items: center;

			header {
				display: flex;
				align-items: center;
				width: 100%;

				button {
					margin: 20.58px 37px 20.58px 21px;
					padding: 0;
					color: var(--font-color);
				}
			}
		}
		
		.flex-container #main-container {
			margin-top: 32px;
			width: 100%;
			background-color: var(--secondary-color);
		}
		
		.buttons-container {
			height: 36px;
			display: flex;
			flex-direction: row;
            justify-content: flex-end;
		}

		div.form-field {
			margin-top: 24px;
		}

		.main-comment {
			width: 100%;
			margin-bottom: 24px;
		}
	}

	@media (min-width: 1100px) {
		.MuiDrawer-paper {
			background-color: var(--background-color);

			.flex-container form#main-container {
				width: 80%;
				margin-right: 8%;
			}

			.flex-container #main-container {
				width: 90%;
				margin: 32px 40px;
				padding: 16px;
				border-radius: 4px;
			}
		}
	}
`