import { StyledTextField } from './styles'

function InputBar(props) {
	return <StyledTextField
		name={props.name}
		value={props.value}
		type={props.type}
		id={props.id}
		className={props.className}
		style={props.style}
		variant="filled"
		label={props.label}
		InputProps={props.InputProps}
		inputProps={props.inputProps}
		required={props.required}
		helperText={props.helperText}
		multiline={props.multiline}
		onChange={props.onChange}
    />
}

export default InputBar