import StyledButton from './styles'

function Button(props) {
	return (
		<StyledButton
			type={props.type}
			style={props.style}
			variant={props.variant}
			active={props.active}
			onClick={props.onClick}
		>
        	{props.children}
		</StyledButton>
	)
}

export default Button