import React, { useContext, createContext, useMemo } from "react";

import useLoadGoogleAuthScript from "../hooks/useLoadGoogleAuthScript";

const GoogleOAuthContext = createContext(null);

export default function GoogleOAuthProvider({
  clientId,
  nonce,
  onScriptLoadSuccess,
  onScriptLoadError,
  children,
}) {
  const scriptLoadedSuccessfully = useLoadGoogleAuthScript({
    nonce,
    onScriptLoadSuccess,
    onScriptLoadError,
  });

  const contextValue = useMemo(
    () => ({
      clientId,
      scriptLoadedSuccessfully,
    }),
    [clientId, scriptLoadedSuccessfully]
  );

  return (
    <GoogleOAuthContext.Provider value={contextValue}>
      {children}
    </GoogleOAuthContext.Provider>
  );
}

export function useGoogleOAuth() {
  const context = useContext(GoogleOAuthContext);
  if (!context) {
    throw new Error(
      "Google OAuth components must be used within GoogleOAuthProvider"
    );
  }
  return context;
}
