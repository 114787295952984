import { Avatar } from '@material-ui/core'
import Text from '../Text'
import StyledDiv from './styles'

function Commentary(props) {

	return (
		<StyledDiv style={{ flexDirection: "row" }}>
			<Avatar src={props.avatar} alt={`avatar de ${props.author}`} style={{ width: props.main ? 40 : 30, height: props.main ? 41 : 31}} />
			<div>
				<Text variant={props.variant}>{props.author}</Text>
				<Text style={{marginTop: '0.5rem'}}>
					{props.children}
				</Text>
			</div>
		</StyledDiv>
	)
}

export default Commentary