class AuthService {
  getLoggedUser() {
    return localStorage.getItem('loggedUser');
  }

  setLoggedUser(userData) {
    return localStorage.setItem('loggedUser', JSON.stringify(userData));
  }

  googleLogout() {
    if(window.google && window.google.accounts) {
      window.google.accounts.id.disableAutoSelect();
      localStorage.removeItem('loggedUser');
    }
  }
}

export default new AuthService();