export function isFunction(fn) {
  return typeof fn === "function";
}

export function extractClientId(credentialResponse) {
  if (!credentialResponse) {
    return credentialResponse;
  }

  const clientId = credentialResponse.clientId || credentialResponse.client_id;
  return clientId;
}
