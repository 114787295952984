import React, { useState, useEffect, useRef, useCallback } from 'react';
import axios from 'axios';
import { InputAdornment, IconButton, Divider, CircularProgress } from '@material-ui/core';
import { Search, Close } from '@material-ui/icons';
import '../../assets/styles/App.css';
import NumberFormat from 'react-number-format';
import Text from '../../assets/components/Text';
import InputBar from '../../assets/components/InputBar';
import OfferCard from '../../assets/components/OfferCard';
import AddOfferButton from '../../assets/components/AddOfferButton';
import PageUpButton from '../../assets/components/PageUpButton';
import MainContainer from '../../assets/components/MainContainer';
import MenuBar from '../../assets/components/MenuBar';
import Button from '../../assets/components/Button';
import { ButtonsContainer } from './styles';
import debounce from 'lodash.debounce';
import notFoundImage from '../../assets/images/not-found.svg';
import { useAuth } from '../../providers/AuthProvider';
import fetcher from '../../handlers/api';

const drawerAnchor = () => (window.innerWidth <= 1099 ? 'bottom' : 'right');

function NumberFormatCustom(props) {
  const { inputRef, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      prefix="R$ "
      allowNegative={false}
      allowedDecimalSeparators={[',', '.']}
      decimalSeparator=","
      thousandSeparator="."
      isNumericString
      decimalScale={2}
      fixedDecimalScale
    />
  );
}

function handleSubmit(event, toggleDrawer, userId) {
  event.preventDefault();

  const requiredFields = document.querySelectorAll('.required-field');
  const urlField = document.querySelector('input[type=url]');
  const commentField = document.querySelector('#comment-field');
  const data = new FormData(event.target);
  const formValues = Object.fromEntries(data.entries());

  const urlExpression = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
  const urlRegex = new RegExp(urlExpression);

  for (let index = 0; index < requiredFields.length; index++) {
    const field = requiredFields[index];
    if (field.value.length === 0 || field.value === undefined || field.value === '') {
      window.alert("Por favor preencha corretamente os campos obrigatórios (marcados com '*')");
      field.focus();

      return false;
    } else if (urlField.value.match(urlRegex) === null) {
      window.alert("Por favor preencha corretamente os campos obrigatórios (marcados com '*')");
      urlField.focus();

      return false;
    } else if (commentField.textLength > 300) {
      window.alert("Por favor refaça seu comentário de modo que não passe de 300 caracteres");
      commentField.focus();

      return false;
    } else {
      try {
        fetcher.post('/pendants', {...formValues, user_id: userId});
        toggleDrawer(drawerAnchor(), false);
      } catch (error) {
        console.error(error);
      }
      return false;
    }
  }
}

function AddOfferMenu({ drawerDirection, toggleDrawer }) {
  const [remainingCharacters, setRemainingCharacters] = useState(300);
  const {userData: {id: userId}} = useAuth();

  return (
    <MenuBar id="add-offer-menu" anchor={drawerAnchor()} open={drawerDirection[drawerAnchor()]}>
      <div className="flex-container">
        <header>
          <IconButton onClick={() => toggleDrawer(drawerAnchor(), false)}>
            <Close />
          </IconButton>
          <Text variant="h6">Adicionar nova oferta</Text>
        </header>
        <Divider />
        <form
          id="main-container"
          name="offerInfos"
          encType="multipart/form-data"
          onSubmit={(event) => {
            event.preventDefault();
            handleSubmit(event, toggleDrawer, userId);
          }}
          style={{
            marginLeft: drawerAnchor() === 'bottom' && '5%',
            marginRight: drawerAnchor() === 'right' && '8%',
          }}
        >
          <Text style={{ marginBottom: 5 }}>
            Compartilhe com a comunidade
          </Text>
          <InputBar
            name="title"
            label="Título do produto"
            className="form-field"
            inputProps={{ className: 'required-field' }}
            required
          />
          <InputBar
            name="link"
            type="url"
            label="Link da oferta"
            className="form-field"
            helperText="Ex: https://google.com"
            inputProps={{ className: 'required-field' }}
            required
          />
          <InputBar
            name="image_url"
            id="image-field"
            type="text"
            label="Link da imagem da oferta"
            className="form-field"
            helperText='Clique com o mouse direito sobre a imagem da oferta e selecione "copiar endereço da imagem"'
          />
          <InputBar
            name="price"
            label="Preço"
            id="price-field"
            className="form-field"
            style={{ width: '50%' }}
            InputProps={{ inputComponent: NumberFormatCustom }}
            inputProps={{ width: '80%', className: 'required-field' }}
            required
          />
          <InputBar
            name="comment"
            label="Comentário (opcional)"
            id="comment-field"
            className="form-field"
            multiline
            inputProps={{ maxLength: 300 }}
            onChange={() => setRemainingCharacters(300 - document.querySelector('#comment-field').textLength)}
            helperText={`${remainingCharacters} caracteres restantes`}
          />
          <div
            className="buttons-container"
            style={{
              marginTop: 25,
              marginBottom: 21,
              width: drawerAnchor() === 'bottom' ? '91%' : '100%',
            }}
          >
            <Button
              variant="contained" 
              type="submit"
            >
              enviar
            </Button>
          </div>
        </form>
      </div>
    </MenuBar>
  );
}

function Home() {
  const [drawerDirection, setDrawerDirection] = useState({
    bottom: false,
    right: false,
  });
  const [offers, setOffers] = useState([]);
  const [comments, setComments] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(false);
  const [queryString, setQueryString] = useState('');

  const {hasUserLogged} = useAuth();

  const observer = useRef();
  const lastCardRef = useCallback((node) => {
    if (loading) return;
    if (observer.current) observer.current.disconnect();

    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && hasMore) {
        setPageNumber((prevPageNumber) => prevPageNumber + 1);
      };
    });

    if (node) observer.current.observe(node);
  }, [loading, hasMore]);

  function toggleDrawer(anchor, open) {
    setDrawerDirection({ ...drawerDirection, [anchor]: open });
  };


  async function getOffers(query, page) {
    let cancel;

    try {
      setLoading(true);
      const response = await fetcher.get('/offers', {
        cancelToken: new axios.CancelToken((c) => (cancel = c)),
        params: { q: query, page: page }
      });

      setOffers((prevOffers) => {
        return [...prevOffers, ...response.data.map((obj) => obj)];
      });
      setHasMore(response.data.length > 0);
      setLoading(false);

      cancel();
    } catch (error) {
      if (axios.isCancel(error)) return;
      console.error(error);
    }
  };

  async function getComments() {
    let cancel;

    try {
      const response = await fetcher.get('/comments', {
        cancelToken: new axios.CancelToken((c) => (cancel = c))
      });
      setComments(response.data.map((obj) => obj));

      cancel();
    } catch (error) {
      if (axios.isCancel(error)) return;
      console.error(error);
    }
  };

  function handleQuery(event) {
    setPageNumber(1);
    setQueryString(event.target.value);
  };

  const debouncesRequest = useCallback(
    debounce((newQuery, newPage) => {
      getOffers(newQuery, newPage);
      getComments();
      return;
    }, 500),
    []
  );

  useEffect(() => {
    setOffers([]);
    debouncesRequest(queryString, pageNumber);
  }, [queryString, debouncesRequest]);

  useEffect(() => {
    debouncesRequest(queryString, pageNumber);
  }, [queryString, pageNumber, debouncesRequest]);

  return (
    <div className="App">
      <MainContainer id="flex-container">
        <Text variant="h5">
          Seu site de ofertas
        </Text>
        <InputBar
          style={{ width: '97%', marginTop: 32 }}
          label="O que você procura?"
          InputProps={{ endAdornment: <InputAdornment position="end"><Search /></InputAdornment> }}
          onChange={handleQuery}
        />
        <Text variant="subtitle1" style={{ marginTop: 56 }}>
          {queryString === "" ? "As mais recentes" : `${offers.length} resultados encontrados`}
        </Text>

        {
          offers.map((currOffer, index) => (
              <div
                ref={index === offers.length -1 ? lastCardRef : undefined}
                key={currOffer.id}
              >
                <OfferCard
                  key={currOffer.id}
                  offer={currOffer}
                  comments={comments}
                />
              </div>
            )
          )
        }

        {
          offers.length === 0
          && queryString !== ""
          && !loading
          && <img style={{ width: 93, margin: '56px 40% 48px' }} src={notFoundImage} alt="not found" />}

        {loading && <CircularProgress style={{ color: 'var(--primary-color)', marginLeft: '50%' }} />}

        {hasUserLogged && (
          <ButtonsContainer>
            <PageUpButton />
            <AddOfferButton
              onClick={() => {
                toggleDrawer(drawerAnchor(), true);
              }}
            />
          </ButtonsContainer>
        )}
      </MainContainer>

      <AddOfferMenu drawerDirection={drawerDirection} toggleDrawer={toggleDrawer} />
    </div>
  );
}

export default Home;
