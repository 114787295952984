import '../../assets/styles/App.css'
import Text from '../../assets/components/Text'
import MainContainer from '../../assets/components/MainContainer'

function About() {
	return (
		<div className="App">
			<MainContainer>
				<Text variant="h5" style={{ marginBottom: 48 }}>
					O que é o Myloot?
				</Text>
				<Text variant="body1" paragraph>
					O lugar certo para encontrar ofertas imperdíveis!
				</Text>
				<Text variant="body1" paragraph>
					Nossa comunidade é formada por pessoas como você, que adoram economizar e encontrar produtos de qualidade. Aqui tem ofertas de todas as categorias, desde eletrônicos até moda e beleza.
				</Text>
				<Text variant="body1" paragraph>
					Além disso, você pode tirar dúvidas, pedir opiniões e compartilhar ofertas bacanas aqui na comunidade.
				</Text>
				<Text variant="body1" paragraph>
					Faça parte do Myloot e economize muito!
				</Text>
			</MainContainer>
		</div>
	)
}

export default About
