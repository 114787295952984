import styled from "styled-components";

const FooterStyled = styled.footer`
  text-transform: uppercase;
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 1.25px;
  color: var(--secondary-font-color);
  padding: 0.5rem;

  @media (min-width: 1100px) {
    flex-direction: row;
    a::before {
      content: ' - ';
      color: var(--secondary-font-color);
    }
    p::after {
      content: "";
      margin-left: 2.25px;
    }
  }
`;

export default FooterStyled;
