import { useCallback, useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { AppBar, Dialog, DialogActions, DialogTitle, Divider, Grid, IconButton, Toolbar, Typography } from '@material-ui/core'
import { Close as CloseIcon, Menu } from '@material-ui/icons'
import { StyledToolBar, UserProfileContainer } from './styles'
import Button from '../Button'
import MenuBar from '../MenuBar'
import Text from '../Text'
import GoogleButtonLogin from '../GoogleButtonLogin';
import logotipo from '../../images/logotipo.svg'
import { useAuth } from '../../../providers/AuthProvider';
import { Profile } from '../Profile'
import { DialogTransition } from '../DialogTransition'
import { useWindowSize } from '../../../hooks/useWindowSize'


function NavBar() {
	const [opened, setOpened] = useState(false);
	const [openLogoutConfirmation, setOpenLogoutConfirmation] = useState(false);
	const {hasUserLogged, userData, logout} = useAuth();
	const [googleButtonLoginSize, setGoogleButtonLoginSize] = useState(200);
	const {width} = useWindowSize();

	useEffect(()=> {
		setGoogleButtonLoginSize(
			Math.round(((window.innerWidth * 80)/100)-32)
		);
	},[width])

	const {pathname} = useLocation();
	const actualPage = pathname;
	let isHomeActive
	let isAboutActive
	let isContactActive

	function toggleDrawer() {
		setOpened(!opened)
	}

	switch (actualPage) {
		case '/about':
			isAboutActive = "true"
			break
		case '/contact':
			isContactActive = "true"
			break
		case '/':
			isHomeActive = "true"
			break
		default:
			break
	}

	const menuContent = [
		<Link key="home" to="/">
			<Button active={isHomeActive} variant="text" onClick={() => isHomeActive && window.location.reload()}>página inicial</Button>
		</Link>,
		<Link key="about" to="/about">
			<Button active={isAboutActive} variant="text">sobre</Button>
		</Link>,
		<Link key="contact" to="/contact">
			<Button active={isContactActive} variant="text">contato</Button>
		</Link>,
	]

	const handleCloseLogoutDialog = useCallback(()=> {
		setOpenLogoutConfirmation(false);
	},[])

	return (
		<>
			<AppBar style={{ backgroundColor: "var(--background-color)" }}>
				<StyledToolBar>
					<Link key="home" to="/" onClick={() => window.location.replace("https://myloot.com.br")}>
						<img src={logotipo} alt="Logotipo da Myloot" />
					</Link>

					<div className="mobile-toolbar" onClick={toggleDrawer}>
						<IconButton>
							<Menu style={{ color: "white" }} />
						</IconButton>

						<MenuBar open={opened} anchor="left">
							<Grid container direction='column' style={{height: '100%'}}>
								<Text variant="h6" style={{ padding: "12px 16px" }}
								>
									Menu
								</Text>
								<Divider style={{ backgroundColor: "#535353" }} />
								{menuContent}
								{hasUserLogged ? (
									<UserProfileContainer>
										<Profile showAccountInfo user={userData}/>
										<Button
											variant='active'
											style={{
												margin: '1.5rem auto 0',
												letterSpacing: 1.25,
											}}
											onClick={()=> setOpenLogoutConfirmation(true)}
										>
											Sair da Conta
										</Button>
									</UserProfileContainer>
								) : (
									<GoogleButtonLogin 
										key="google-login-button"
										type='outline'
										logo_alignment='left'
										width={`${googleButtonLoginSize}px`}
										containerProps={{style: {margin: "auto 1rem 1rem"}}}
									/>
								)}
							</Grid>
						</MenuBar>
					</div>

					<div className="desktop-toolbar" >
						{menuContent}
						{hasUserLogged ? (
							<Profile
								user={userData}
								onClick={()=> setOpenLogoutConfirmation(true)}
							/>
						) : (
							<GoogleButtonLogin 
								key="google-login-button"
								type='outline'
								logo_alignment='left'
							/>
						)}
					</div>
				</StyledToolBar>
			</AppBar>
			<Toolbar />
			<Dialog
				TransitionComponent={DialogTransition}
				onClose={handleCloseLogoutDialog}
				open={openLogoutConfirmation}
				fullWidth
				maxWidth='xs'
				aria-labelledby="Sair da conta ?"
			>
				<DialogTitle
					disableTypography
					style={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'space-between'
					}}>
					<Typography variant='h6'>Sair da conta?</Typography>
					<IconButton style={{padding: 0}} onClick={handleCloseLogoutDialog}>
						<CloseIcon color='secondary'/>
					</IconButton>
				</DialogTitle>
				<DialogActions>
					<Button
						variant='active'
						onClick={()=> {
							logout();
							handleCloseLogoutDialog(false)
						}}
					>
						CONFIRMAR
					</Button>
				</DialogActions>
			</Dialog>
		</>
	)
}

export default NavBar
