import { Fab } from '@material-ui/core'
import styled from 'styled-components'

export const StyledFab = styled(Fab)`
    margin: 4px;
    background-color: var(--primary-color);

    :hover {
        background-color: #4bce77;
    }
`