import { Add } from '@material-ui/icons';
import { StyledFab } from './styles';

function AddOfferButton({ onClick }) {
  return (
    <StyledFab aria-label="add offer" onClick={onClick}>
      <Add />
    </StyledFab>
  );
}

export default AddOfferButton;
