import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import Home from "./pages/Home";
import Contact from "./pages/Contact";
import About from "./pages/About";

import "./assets/styles/global.css";
import "./index.css";
import GoogleOAuthProvider from "./providers/GoogleAuthProvider";
import AuthProvider from "./providers/AuthProvider";
import { ThemeProvider } from "@material-ui/core";
import { materialUITheme } from "./themes/materialUITheme";
import { GOOGLE_AUTH_CLIENT_ID } from "./constants";
import NavBar from "./assets/components/NavBar";
import { ServiceTermsPage, PrivacyTermsPage } from "./pages/Terms";
import Footer from "./assets/components/Footer";

ReactDOM.render(
  <ThemeProvider theme={materialUITheme}>
    <GoogleOAuthProvider clientId={GOOGLE_AUTH_CLIENT_ID}>
      <AuthProvider>
        <Router>
          <NavBar />
          <Switch>
            <Route path="/" exact component={Home} />
            <Route path="/contact" component={Contact} />
            <Route path="/about" component={About} />
            <Route path="/terms" component={ServiceTermsPage} />
            <Route path="/privacy" component={PrivacyTermsPage} />
          </Switch>
          <Footer id="copyright-footer" />
        </Router>
      </AuthProvider>
    </GoogleOAuthProvider>
  </ThemeProvider>,
  document.getElementById("root")
);
