import { useState, useEffect } from 'react';
import { CardContent, Divider, IconButton, Link, Tooltip } from '@material-ui/core';
import { Close, Comment } from '@material-ui/icons';
import axios from 'axios';
import Button from '../Button';
import MenuBar from '../MenuBar';
import Text from '../Text';
import Commentary from '../Commentary';
import InputBar from '../InputBar';
import { CardContainer, StyledCard, StyledCardMedia } from './styles';
import defaultAvatar from '../../images/default-avatar.png';
import { useAuth } from '../../../providers/AuthProvider';
import fetcher from '../../../handlers/api';

const drawerAnchor = () => window.innerWidth <= 1099 ? "bottom" : "right";

function calcElapsedTimeSinceCreation(created_at) {
	const createdAt = created_at.toString();
	const dateObject = new Date(createdAt);
	const elapsedMillisecondsSinceCreation = new Date() - dateObject;
	const isMoreThanOne = (convDivider) => elapsedMillisecondsSinceCreation > convDivider * 2 ? true : false;
	const timeSinceCreationConverted = (convDivider) => elapsedMillisecondsSinceCreation/convDivider;

	let elapsedTime;
	let conversionDivider;

	if (elapsedMillisecondsSinceCreation >= 1000) {
		conversionDivider = 1000; 
		elapsedTime =
			`HÁ ${Math.floor(timeSinceCreationConverted(conversionDivider))} SEGUNDO${isMoreThanOne(conversionDivider) ? 'S' : ''}`;
	} else {
		elapsedTime = `HÁ ${Math.floor(elapsedMillisecondsSinceCreation)} MILISSEGUNDOS ATRÁS`;
	};
	if (elapsedMillisecondsSinceCreation >= 60000) {
		conversionDivider = 60000; 
		elapsedTime =
			`HÁ ${Math.floor(timeSinceCreationConverted(conversionDivider))} MINUTO${isMoreThanOne(conversionDivider) ? 'S' : ''}`;
		};
	if (elapsedMillisecondsSinceCreation >= 3600000) {
		conversionDivider = 3600000; 
		elapsedTime =
			`HÁ ${Math.floor(timeSinceCreationConverted(conversionDivider))} HORA${isMoreThanOne(conversionDivider) ? 'S' : ''}`;	
		};
	if (elapsedMillisecondsSinceCreation >= 86400000) {
		conversionDivider = 86400000;
		elapsedTime =
			`HÁ ${Math.floor(timeSinceCreationConverted(conversionDivider))} DIA${isMoreThanOne(conversionDivider) ? 'S' : ''}`;	
		};
	if (elapsedMillisecondsSinceCreation >= 2592000000) {
		conversionDivider = 2592000000;
		elapsedTime =
			`HÁ ${Math.floor(timeSinceCreationConverted(conversionDivider))} MES${isMoreThanOne(conversionDivider) ? 'ES' : ''}`;
	};

	return elapsedTime;
}

function CommentsDrawer({
	drawerDirection,
	toggleDrawer,
	description,
	offerComments,
	offerId,
	user_name,
	user_picture 
}) {
	const [remainingCharacters, setRemainingCharacters] = useState(300);
	const [comments, setComments] = useState([]);
	const [comment, setComment] = useState("");
	const {hasUserLogged, userData: {id: userId}} = useAuth();

	useEffect(() => {
		setComments(offerComments);
	}, [offerComments])

	function validateForm(event) {
		event.preventDefault();
		const commentField = document.querySelector('#comment-field');
		
		commentField.value = "";
		if (comment.length === 0 || comment === undefined || comment === "") {
			window.alert("Por favor preencha corretamente os campos obrigatórios (marcados com '*')");
			commentField.focus();

			return false;
		} else if (commentField.textLength > 300) {
			window.alert("Por favor refaça seu comentário de modo que não passe de 300 caracteres");
			commentField.focus();

			return false;
		} else {
			fetcher.post('/comments', {
				offer_id: offerId,
				commentary: comment,
				user_id: userId
			})
			.then(() => {
				fetcher.get('/comments').then(response => {
					let newCommentsList = [];
					response.data.forEach(item => {
						if (offerId === item.offer_id) {
							newCommentsList.push(item);
						};
					});
					setComments(newCommentsList);
					setComment("");
				})
			})

			return false;
		}
	}

	return (
		<MenuBar anchor={drawerAnchor()} open={drawerDirection[drawerAnchor()]}>
			<div className="flex-container">
				<header>
					<IconButton onClick={() => toggleDrawer(drawerAnchor(), false)}>
						<Close />
					</IconButton>
					<Text variant="h6">Comentários</Text>
				</header>
				<Divider />
				<div id="main-container">
					{description && (
						<>
							<div className="main-comment">
								<Commentary main variant="h6" author={user_name || "Anônimo"} avatar={user_picture || defaultAvatar}>
									{description}
								</Commentary>
							</div>
							<Divider />
						</>
					)}
					{hasUserLogged && (
						<div className="comment-actions" style={{ alignItems: "center" }}>
							<form
								id="comment-form"
								name="commentForm"
								encType="multipart/form-data"
								style={{ width: '98%', marginLeft: 30 }}
								onSubmit={validateForm}
							>
								<InputBar
									id="comment-field"
									className="required-field"
									value={comment}
									label="Faça um comentário"
									style={{ margin: "32px 0 16px" }}
									inputProps={{ maxLength: 300 }}
									onChange={(event) => {setRemainingCharacters(300 - comment.length); setComment(event.target.value)}}
									helperText={`${remainingCharacters} caracteres restantes`}
									multiline
									required
								/>
								<div className="buttons-container" style={{ width: "91%" }}>
									<Button active="true" variant="text" onClick={() => setComment("")}
									style={{
										marginRight: "8px"
									}}
									>
										limpar
									</Button>

									<Button type="submit" variant="contained">enviar</Button>
								</div>
							</form>
						</div>
					)}
					{comments.map((comment) => (
						<Commentary
							key={comment.id}
							variant="subtitle2"
							author={comment['user_name'] || "Anônimo"}
							avatar={comment['user_picture'] || defaultAvatar}
						>
							{comment.commentary}
						</Commentary>
					))}
				</div>
			</div>
		</MenuBar>
	)
}

function OfferCard({ offer, comments}) {
	const {
		id,
		title,
		price,
		link,
		affiliate_link,
		description,
		image_url,
		created_at,
		user_name,
		user_picture
	} = offer;
	
	const [drawerDirection, setDrawerDirection] = useState({bottom: false, right: false});
	const [offerLink, setOfferLink] = useState(link);
	
	const timeSinceCreation = calcElapsedTimeSinceCreation(created_at);
	const lomadeeAPIURL = process.env.NODE_ENV === 'development' ?
	`http://sandbox-api.lomadee.com/v2/1639530269978942b5f39/deeplink/_create?sourceId=37320011&url=${link}` :
	`https://api.lomadee.com/v2/1639530269978942b5f39/deeplink/_create?sourceId=37320011&url=${link}`;

	let offerComments = [];

	function toggleDrawer(anchor, isOpened) {
		setDrawerDirection({ ...drawerDirection, [anchor]:isOpened });
	}

	comments.forEach((currComment) => {
		if (currComment.offer_id === id) {
			offerComments.push(currComment);
		}
	});

	async function getLomadeeLink() {
		try {
			let lomadeeResponse = await fetch(lomadeeAPIURL);
			let data = await lomadeeResponse.json();
			let deeplink = await data.deeplinks[0].deeplink;			
			if (typeof deeplink === 'string') setOfferLink(deeplink);
		} catch (error) {
			console.error(error);
		}
	}

	useEffect(() => {
		getLomadeeLink();
	}, []);

	return (
		<CardContainer>
				<Text className="created-at" variant="overline">
					{timeSinceCreation}
				</Text>
			<StyledCard>
				<CardContent className="card-content">
					<StyledCardMedia
						image={image_url}
						title={title}
					/>
					<div className="main-container">
						<Tooltip arrow title={title}>
							<div>
								<Text variant="h6" component="h2">{title}</Text>
							</div>
						</Tooltip>
						<Link href={affiliate_link} target="_blank" rel="noopener">
							<Text variant="caption">
								{new URL(link).hostname}
							</Text>
						</Link>

						<Text variant="body2" style={{ marginTop: 24 }}>
							{description}
						</Text>

						<div className="buttons-container">
							<Button
								active="true"
								variant="text"
								onClick={() => toggleDrawer(drawerAnchor(), true)}
							>
								<span>
									<Comment color="inherit"/>
									{offerComments.length}
								</span>
							</Button>
							<Link href={affiliate_link} target="_blank" rel="noopener" style={{textDecoration: 'none'}}>
								<Button variant="contained">
									{price}
								</Button>
							</Link>
						</div>
					</div>
				</CardContent>
				
				<CommentsDrawer
					offerId={id}
					offerComments={offerComments}
					description={description}
					user_name={user_name}
					user_picture={user_picture}
					toggleDrawer={toggleDrawer}
					drawerDirection={drawerDirection}
				/>
			
		</StyledCard>
	</CardContainer>
	)
}

export default OfferCard
