import { createMuiTheme } from "@material-ui/core";

export const materialUITheme = createMuiTheme({
  palette: {
    primary: {
      main: "#46FF83",
      contrastText: "#2d2d2d",
    },
    secondary: {
      main: "#ffffffde",
    },
    background: {
      paper: "#3e3e3e",
      default: "#2d2d2d",
    },
    text: {
      primary: "#ffffffde",
      secondary: "#ffffffde",
    },
    divider: "#ffffff1e",
  },
});