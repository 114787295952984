import "../../assets/styles/App.css";
import Text from "../../assets/components/Text";
import MainContainer from "../../assets/components/MainContainer";
import Paragraph from "../../assets/components/Paragraph";

function ServiceTermsPage() {
  return (
    <div className="App">
      <MainContainer>
        <Text variant="h5" style={{ marginBottom: 48 }}>
          Termos e condições de uso
        </Text>
        <Paragraph title="1. Apresentação">
          O Myloot é uma plataforma online que permite aos seus usuários
          compartilhar ofertas e promoções, recomendar produtos e dar sua
          opinião sobre as ofertas disponíveis.
        </Paragraph>
        <Paragraph title="2. Aceitação dos Termos e Condições">
          Ao acessar ou utilizar a Myloot, você concorda em estar sujeito a
          estes Termos e Condições de Uso. Se você não concordar com estes
          Termos, você não deve acessar ou utilizar o Myloot.
        </Paragraph>
        <Paragraph title="3. Registro e Conta de Usuário">
          Para utilizar o Myloot, você precisa se registrar e criar uma conta de
          usuário. Você deve fornecer informações precisas e atualizadas sobre
          sua pessoa. Você é responsável por manter a confidencialidade de sua
          senha e conta.
        </Paragraph>
        <Paragraph title="4. Conteúdo do Usuário">
          Você é o único responsável pelo conteúdo que você postar no Myloot. Ao
          postar conteúdo na comunidade, você concede ao Myloot uma licença não
          exclusiva, mundial, livre de royalties, sublicenciável e transferível
          para usar, reproduzir, distribuir, preparar trabalhos derivados,
          exibir e apresentar publicamente tal conteúdo em qualquer forma e
          meio.
        </Paragraph>
        <Paragraph title="5. Regras de Conduta">
          Ao utilizar o Myloot, você concorda em:
          <ul>
            <li>
              Não postar conteúdo que seja ilegal, difamatório, odioso,
              discriminatório ou que de outra forma viole os direitos de
              terceiros;
            </li>
            <li>Não postar conteúdo que seja spam ou publicidade;</li>
            <li>
              Não se envolver em qualquer comportamento que possa prejudicar ou
              danificar a Myloot ou seus usuários.
            </li>
          </ul>
        </Paragraph>
        <Paragraph title="6. Moderação de Conteúdo">
          O Myloot se reserva o direito de moderar o conteúdo postado na
          plataforma. O Myloot pode remover qualquer conteúdo que viole estes
          Termos ou que, de outra forma, seja considerado inapropriado pelos
          seus Administradores ou Moderadores.
        </Paragraph>
        <Paragraph title="7. Propriedade Intelectual">
          O Myloot possui todos os direitos de propriedade intelectual sobre a
          plataforma Myloot. Você não pode copiar, modificar ou distribuir
          qualquer parte do Myloot sem a autorização expressa do Myloot.
        </Paragraph>
        <Paragraph title="8. Isenção de Responsabilidade">
          O Myloot não é responsável por qualquer conteúdo postado pelos
          usuários da plataforma. O Myloot não garante a qualidade, a segurança
          ou a confiabilidade de qualquer produto ou serviço compartilhado pelos
          usuários da comunidade.
        </Paragraph>
        <Paragraph title="9. Limitação de Responsabilidade">
          Em nenhum caso o Myloot será responsável por qualquer dano direto,
          indireto, incidental, especial, consequencial ou exemplar, incluindo,
          mas não se limitando a, danos por perda de lucros, perda de dados ou
          perda de uso, mesmo que o Myloot tenha sido avisado da possibilidade
          de tais danos.
        </Paragraph>
        <Paragraph title="10. Rescisão">
          O Myloot pode rescindir sua conta a qualquer momento, por qualquer
          motivo, com ou sem aviso prévio.
        </Paragraph>
        <Paragraph title="11. Lei Aplicável e Foro Competente">
          Estes Termos serão regidos e interpretados de acordo com as leis da
          República Federativa do Brasil.
        </Paragraph>
        <Paragraph title="12. Disposições Gerais">
          Estes Termos constituem o acordo integral entre você e o Myloot com
          relação ao uso da plataforma. Estes Termos podem ser alterados pelo
          Myloot a qualquer momento, mediante a publicação de uma versão
          revisada dos Termos no Myloot.
        </Paragraph>
        <Paragraph title="13. Contato">
          Se você tiver qualquer dúvida sobre estes Termos, você pode entrar em
          contato com a Myloot através do e-mail:{" "}
          <a href="mailto:contato@myloot.com.br">contato@myloot.com.br</a>.
        </Paragraph>
      </MainContainer>
    </div>
  );
}

export default ServiceTermsPage;
