import styled from 'styled-components'
import '../../assets/styles/App.css'

export const ButtonsContainer = styled.div`
  display: flex;
  position: fixed;
  align-items: center;
  justify-content: end;
  
  bottom: 20px;
  right: 1%;
`