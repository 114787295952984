import React, { useEffect, useRef } from "react";

import { useGoogleOAuth } from "../../../providers/GoogleAuthProvider";
import { isFunction, extractClientId } from "../../../utils";
import { useAuth } from "../../../providers/AuthProvider";
const containerHeightMap = { large: 40, medium: 32, small: 20 };

const GoogleButtonLogin = ({
  onSuccess,
  onError,
  useOneTap,
  promptMomentNotification,
  type = "standard",
  theme = "outline",
  size = "large",
  text,
  shape,
  logo_alignment,
  width,
  locale,
  click_listener,
  containerProps,
  ...props
}) => {
  const { login } = useAuth();

  const btnContainerRef = useRef(null);
  const { clientId, scriptLoadedSuccessfully } = useGoogleOAuth();

  const onSuccessRef = useRef(onSuccess);
  onSuccessRef.current = onSuccess;

  const onErrorRef = useRef(onError);
  onErrorRef.current = onError;

  const promptMomentNotificationRef = useRef(promptMomentNotification);
  promptMomentNotificationRef.current = promptMomentNotification;

  useEffect(() => {
    if (!scriptLoadedSuccessfully) {
      return;
    }

    if (
      !(window.google && window.google.accounts && window.google.accounts.id)
    ) {
      return;
    }

    window.google.accounts.id.initialize({
      client_id: clientId,
      auto_select: false,
      callback: (credentialResponse) => {
        if (!credentialResponse.credential) {
          if (isFunction(onErrorRef.current)) {
            return onErrorRef.current();
          }
          return;
        }

        const { credential, select_by } = credentialResponse;
        if (isFunction(onSuccessRef.current)) {
          onSuccessRef.current({
            credential,
            clientId: extractClientId(credentialResponse),
            select_by,
          });
        }
        login(credential);
      },
      ...props,
    });

    window.google.accounts.id.renderButton(btnContainerRef.current, {
      type,
      theme,
      size,
      text,
      shape,
      logo_alignment,
      width,
      locale,
      click_listener,
    });

    if (useOneTap)
      window.google.accounts.id.prompt(promptMomentNotificationRef.current);

    return () => {
      if (useOneTap) {
        window.google.accounts.id.cancel();
      }
    };
  }, [
    clientId,
    scriptLoadedSuccessfully,
    useOneTap,
    type,
    theme,
    size,
    text,
    shape,
    logo_alignment,
    width,
    locale,
  ]);

  return (
    <div
      {...containerProps}
      ref={btnContainerRef}
      style={{
        height: containerHeightMap[size],
        ...(containerProps || {}).style,
      }}
    />
  );
};

export default GoogleButtonLogin;
