import { Button } from '@material-ui/core'
import styled from 'styled-components'

const StyledButton = styled(Button)`
	justify-content: start;
	color: var(--secondary-font-color);
	background-color: ${props => props.variant === "contained" ? "var(--primary-color)" : "unset"};
	
	color: ${props =>
		props.variant === "contained" 
			? "var(--background-color)" 
			:"var(--primary-color)"
	};
	
	span {
        display: flex;
		align-items: center;
	}
	
    svg {
        padding-right: 10px;
	}
	
	:hover {
		background-color: ${props => props.variant === "contained" ? "#46ff84b0" : ""};
	}
`

export default StyledButton