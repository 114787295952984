import { TextField } from '@material-ui/core'
import styled from 'styled-components'

export const StyledTextField = styled(TextField)`
	width: 91%;

	input {
		width: 100%;
		width: -moz-available;
		width: -webkit-fill-available;
		width: fill-available;
	}

	p {
		color: var(--secondary-font-color)
	}

  .MuiFormLabel-root {
    color: #787878;
  }
  
  .MuiFormLabel-root.Mui-focused {
    color: var(--primary-color);
  }
  
  .MuiFilledInput-root {
    background-color: #3e3e3e;
    color: var(--font-color);
  }

  .MuiFilledInput-root::before {
    right: unset;
    top: unset;
    left: unset;
    bottom: unset;

    content: "";
    border: 0;
  }

  .MuiFilledInput-root::after {
    border-bottom-color: var(--primary-color);
  }
`