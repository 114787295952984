import Text from "../Text";

function Paragraph({ title, children, ...props }) {
  return (
    <>
      {title && (
        <Text variant='subtitle1'>{title}</Text>
      )}
      <Text {...props} variant='body1' paragraph>
        {children}
      </Text>
    </>
  );
}

export default Paragraph;
