import styled from 'styled-components'

const StyledDiv = styled.div`
	padding: 16px;
	align-items: flex-start;
	word-break: break-all;
	display: flex;

	p {
		color: #b4b4b4;
		line-height: 20px;
		letter-spacing: 0.25px;
	}

	.MuiAvatar-root {
		margin-right: 16px;
	}
`

export default StyledDiv