import MainContainer from "../../assets/components/MainContainer";
import Text from "../../assets/components/Text";
import Paragraph from "../../assets/components/Paragraph";

function PrivacyTermsPage() {
  return (
    <div className="App">
      <MainContainer>
        <Text variant="h5" style={{ marginBottom: 48 }}>
          Política de Privacidade
        </Text>
        <Paragraph title="1. Introdução">
          O Myloot é uma comunidade online onde os usuários podem postar e
          compartilhar promoções na internet, fazer comentários e interagir
          entre si. Esta Política de Privacidade visa esclarecer como coletamos,
          utilizamos, armazenamos e protegemos seus dados pessoais, em
          conformidade com a Lei Geral de Proteção de Dados Pessoais (LGPD) –
          Lei nº 13.709/2018.
        </Paragraph>
        <Paragraph title="2. Coleta de dados">
          Podemos coletar dados pessoais de diversas maneiras, incluindo:
          <ul>
            <li>
              Dados que você fornece: Ao se registrar no Myloot, você pode nos
              fornecer dados como nome, endereço de e-mail, senha, data de
              nascimento e localização.
            </li>
            <li>
              Dados de uso: Coletamos informações sobre como você usa o Myloot,
              como as páginas que você visita, as promoções que você visualiza e
              os comentários que você faz.
            </li>
            <li>
              Dados de dispositivo: Coletamos informações sobre o dispositivo
              que você usa para acessar o Myloot, como o tipo de dispositivo, o
              sistema operacional e o endereço IP.
            </li>
          </ul>
        </Paragraph>
        <Paragraph title="3. Utilização dos Dados">
          Os dados coletados são utilizados para:
          <ul>
            <li>
              Fornecer os serviços do Myloot: Permitimos que você publique e
              compartilhe promoções, faça comentários e interaja com outros
              usuários.
            </li>
            <li>
              Melhorar a experiência do usuário: Personalizamos sua experiência
              no Myloot, recomendando promoções relevantes e mostrando conteúdo
              que você possa ter interesse.
            </li>
            <li>
              Enviar comunicações: Enviamos e-mails com novidades do Myloot,
              promoções e ofertas personalizadas.
            </li>
            <li>
              Realizar pesquisas e análises: Utilizamos dados para entender
              melhor o comportamento dos usuários e melhorar o Myloot.
            </li>
          </ul>
        </Paragraph>
        <Paragraph title="4. Compartilhamento de Dados">
          Seus dados pessoais não serão vendidos ou compartilhados com terceiros
          para fins de marketing sem o seu consentimento. No entanto, podemos
          compartilhar seus dados com:
          <ul>
            <li>
              Prestadores de serviço: Poderemos contratar empresas para nos
              auxiliar em diversas tarefas, como envio de e-mails e análise de
              dados. Essas empresas só podem acessar os dados necessários para
              realizar suas tarefas e são obrigadas a mantê-los confidenciais.
            </li>
            <li>
              Autoridades competentes: Podemos compartilhar seus dados com
              autoridades competentes quando necessário para cumprir uma
              obrigação legal ou para proteger os direitos do Myloot e de seus
              usuários.
            </li>
          </ul>
        </Paragraph>
        <Paragraph title="5. Armazenamento de Dados">
          Seus dados pessoais são armazenados em servidores seguros localizados
          no Brasil ou fora dele. Os dados são armazenados pelo tempo necessário
          para fornecer os serviços do Myloot e para cumprir nossas obrigações
          legais.
        </Paragraph>
        <Paragraph title="6. Seus Direitos">
          Você tem o direito de:
          <ul>
            <li>
              Acessar seus dados: Você pode solicitar uma cópia dos seus dados
              pessoais que armazenamos.
            </li>
            <li>
              Corrigir seus dados: Você pode solicitar a correção de seus dados
              pessoais caso estejam incorretos ou incompletos.
            </li>
            <li>
              Excluir seus dados: Você pode solicitar a exclusão dos seus dados
              pessoais quando não forem mais necessários para os fins para os
              quais foram coletados.
            </li>
            <li>
              Limitar o uso de seus dados: Você pode solicitar que limitemos o
              uso dos seus dados pessoais em determinadas situações.
            </li>
            <li>
              Opor-se ao tratamento de seus dados: Você pode se opor ao
              tratamento dos seus dados pessoais em determinadas situações.
            </li>
            <li>
              Portar seus dados: Você pode solicitar que seus dados pessoais
              sejam transferidos para outro controlador em um formato
              estruturado, de uso corrente e de leitura automática.
            </li>
          </ul>
        </Paragraph>
        <Paragraph title="7. Segurança da Informação">
          O Myloot se compromete a proteger seus dados pessoais contra acessos
          não autorizados, divulgação, perda ou uso indevido. Adotamos diversas
          medidas de segurança para proteger seus dados.
        </Paragraph>
        <Paragraph title="8. Alterações na Política de Privacidade">
          Esta Política de Privacidade pode ser alterada a qualquer momento. Se
          fizermos alterações relevantes, notificaremos você por e-mail ou por
          meio de um aviso no Myloot.
        </Paragraph>
        <Paragraph title="9. Contato">
          Se você tiver alguma dúvida sobre esta Política de Privacidade ou
          sobre o tratamento de seus dados pessoais, entre em contato conosco
          através do e-mail:{" "}
          <a href="mailto:contato@myloot.com.br">contato@myloot.com.br</a>.
        </Paragraph>
        <Paragraph title="10. Lei Aplicável">
          Esta Política de Privacidade será regida e interpretada de acordo com
          a legislação brasileira.
        </Paragraph>
        <Paragraph title="11. Glossário">
          Controlador: Pessoa natural ou jurídica, de direito público ou
          privado, a quem competem as decisões referentes ao tratamento de dados
          pessoais.
        </Paragraph>
      </MainContainer>
    </div>
  );
}

export default PrivacyTermsPage;
