import defaultAvatar from "../../images/default-avatar.png";
import {
  Avatar,
  LogoutButton,
  ProfileContainer,
  ProfileInfoContainer,
  ProfileInfoItem,
} from "./styles";

export const Profile = ({ user = {}, showAccountInfo, ...props }) => {
  return (
    <>
      <ProfileContainer {...props}>
        <Avatar src={user.picture || defaultAvatar} alt="Avatar do usuário" />
        {showAccountInfo && (
          <ProfileInfoContainer>
            <ProfileInfoItem variant="name">
              {user.name || "Anonimo"}
            </ProfileInfoItem>
            <ProfileInfoItem variant="email">{user.email}</ProfileInfoItem>
          </ProfileInfoContainer>
        )}
      </ProfileContainer>
    </>
  );
};
