import { createContext, useContext, useEffect, useState } from "react";
import authService from "../services/AuthService";
import fetcher from "../handlers/api";

const AuthContext = createContext({
  setLoggedUser: () => null,
  logout: () => null,
  userData: {},
});

const AuthProvider = ({ children }) => {
  const [hasUserLogged, setHasUserLogged] = useState(false);
  const [userData, setUserData] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    validateLoggedUser();
  }, []);

  const validateLoggedUser = async () => {
    if (userData) {
      setLoading(true);
      let userData = authService.getLoggedUser();
      try {
        userData = JSON.parse(userData);
        await fetcher.get(`/login/validateToken?token=${userData.token}`);
        setUserData(userData);
        setHasUserLogged(true);
      } catch (error) {
        console.error(error);
        logout();
      } finally {
        setLoading(false);
      }
    }
  };

  const login = async (token) => {
    if (!token) {
      return;
    }
    try {
      setLoading(true);
      const response = await fetcher.get(`/login?token=${token}`);
      setHasUserLogged(true);
      setUserData(response.data);
      authService.setLoggedUser(response.data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const logout = () => {
    authService.googleLogout();
    setHasUserLogged(false);
    setUserData({});
  };

  return (
    <AuthContext.Provider
      value={{ hasUserLogged, userData, login, logout, loading }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};

export default AuthProvider;
