import '../../assets/styles/App.css'
import Text from '../../assets/components/Text'
import MainContainer from '../../assets/components/MainContainer'

function Contact() {
	return (
		<div className="App">
			<MainContainer>
				<Text variant="h5" style={{ marginBottom: 48 }}>Nosso contato</Text>
				<Text variant="body1">
					Caso precise falar conosco, envie uma mensagem para:: <a href="mailto:contato@myloot.com.br" target="_blank" rel="noopener noreferrer">
					contato@myloot.com.br
					</a>
				</Text>
			</MainContainer>
		</div>
	)
}

export default Contact
