import { StyledDrawer } from './styles'

function MenuBar(props) {
    return (
        <div>
            <StyledDrawer id={props.id} anchor={props.anchor} open={props.open}>
                {props.children}
            </StyledDrawer>
        </div>
    )
}

export default MenuBar