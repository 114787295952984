import { Fade } from '@material-ui/core'
import { ArrowUpward } from '@material-ui/icons'
import { useState } from 'react'
import { StyledFab } from './styles'

function PageUpButton() {
    const [visible, setVisible] = useState(false)

    const actualScrollPosition = () => document.documentElement.scrollTop || document.body.scrollTop
    window.addEventListener("scroll", () => {
        setVisible(actualScrollPosition() <= 800 ? false : true)
    })

    function scrollToTop() {
        if (actualScrollPosition() > 0) {
            window.requestAnimationFrame(scrollToTop)
            window.scrollTo(0, actualScrollPosition() - actualScrollPosition() / 10)
        }
    }

    return (
            <Fade in={visible} onClick={scrollToTop}>
                <div>
                    <StyledFab aria-label="page up" size="small">
                        <ArrowUpward />
                    </StyledFab>
                </div>
            </Fade>
    )
}

export default PageUpButton