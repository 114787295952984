import { Typography } from "@material-ui/core";
import styled, { css } from "styled-components";
import Button from "../Button";

const profileItemVariantes = {
  name: css`
    font-size: 16px;
    line-height: 1.5rem;
    letter-spacing: 0.15px;
    color: #ffffffde;
  `,
  email: css`
    font-size: 12px;
    line-height: 1rem;
    letter-spacing: 0.4px;
    color: #FFFFFF99;
  `,
};

export const ProfileContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  gap: 0.5rem;
`;

export const Avatar = styled.img`
  width: 40px;
  height: 40px;
  cursor: pointer;
  border-radius: 50%;
`;

export const ProfileInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ProfileInfoItem = styled(Typography)`
  width: 100%;
  text-overflow: ellipsis;
  ${(props) => profileItemVariantes[props.variant]}
`;

