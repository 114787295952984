import Text from "../Text";
import FooterStyled from "./styles";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <FooterStyled id="copyright-footer">
      <Text>MYLOOT &copy; 2024</Text>
      <Link to="/terms">Termos de serviço</Link>
      <Link to="/privacy">Políticas de privacidade</Link>
    </FooterStyled>
  );
}

export default Footer;
