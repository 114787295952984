import { Fab } from '@material-ui/core'
import styled from 'styled-components'

export const StyledFab = styled(Fab)`
    background-color: var(--secondary-color);
    color: #9f9f9f;

    :hover {
        background-color: var(--background-color);
    }
`