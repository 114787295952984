import { Card, CardMedia } from '@material-ui/core'
import styled from 'styled-components'

export const CardContainer = styled.div`
    .created-at {
        position: relative;
        bottom: -60px;
        left: 16px;

        font-weight: 500;
        letter-spacing: 1.5px;
        line-height: 16px;
        font-size: 13px;
        color: rgba(255, 255, 255, 0.38);
    }
    
    @media (min-width: 1100px) {
        .created-at {
            position: relative;
            bottom: -20px;
            left: 0;
        }
    }
`;

export const StyledCard = styled(Card)`
    width: 97%;
    margin-top: 24px;
    min-height: 202px;
    max-height: 770px;
    max-width: 100%;
    background-color: var(--secondary-color);

	h2 {
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-top: 16px;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        display: -webkit-box;
	}

	a {
		color: var(--secondary-font-color);
	}

    p { 
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        display: -webkit-box;
    }

    .card-content {
        padding-top: 52px;
    }

    .buttons-container {
		display: flex;
		flex-direction: row;
        align-items: center;
        margin-top: 24px;
    }

    @media (min-width: 1100px) {
        background-color: var(--background-color);

        h2 {
            margin: 0;
        }

        .card-content {
            display: flex;
			padding: 16px;
        }

        .main-container {
            width: 100%;
            min-width: 0;
            flex: 1;
            height: min-content;
            margin-left: 24px;
        }

        .buttons-container {
			display: flex;
            justify-content: flex-end;
			position: relative;
			bottom: -12%;
        }
        
        .created-at {
            position: relative;
            bottom: -60px;
        }
    }
`
export const StyledCardMedia = styled(CardMedia)`
    padding-top: 59.6%;
    border-radius: 3px;
    background-size: contain;
    background-color: white;

    @media (min-width: 1100px) {
        padding: 0;
        width: 237px;
        height: 180px;
    }
`